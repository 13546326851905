import React, { useState, useEffect } from 'react';
import '../App.css';
import addHandle from '../Images/addHandle.svg';
import Delete from '../Images/delete-icon.svg';
import { useToastContext } from './Toast';

const ReservedNames = () => {
    const [reservedNames, setReservedNames] = useState([]);
    const [newHandle, setNewHandle] = useState('');
    const [loading, setLoading] = useState(false);
    const { sendSuccess, sendWarning } = useToastContext();

    // Fetch reserved names from API
    const fetchReservedNames = async () => {
        try {
            const e = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'i want in',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: e,
                    request: ['get_reserved_names', {}],
                }),
            });

            const data = await response.json();
            if (data && !data.error) {
                setReservedNames(data.names || []);
            } else {
                sendWarning('Failed to fetch reserved names. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching reserved names:', error);
            sendWarning('Failed to fetch reserved names. Please try again.');
        }
    };

   // Add reserved name
const addReservedName = async () => {
    if (!newHandle.trim()) {
        sendWarning('Handle cannot be empty.');
        return;
    }

    try {
        const e = await window.nostr.signEvent({
            created_at: Math.floor(new Date().getTime() / 1000),
            kind: 10000222,
            tags: [],
            content: 'i want in',
        });

        const requestBody = {
            event_from_user: e,
            request: ["add_reserved_name", { "name": newHandle.trim() }], // Key and value properly quoted
        };

        const response = await fetch('https://admin.primal.net/api2', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody), // JSON.stringify ensures correct formatting
        });

        const data = await response.json();
        if (data && !data.error) {
            setNewHandle('');
            sendSuccess('Handle added successfully.');
            fetchReservedNames(); // Refresh the list
        } else {
            sendWarning('Failed to add the handle. Please try again.');
        }
    } catch (error) {
        console.error('Error adding reserved name:', error);
        sendWarning('Failed to add the handle. Please try again.');
    }
};

const deleteReservedName = async (name) => {
    try {
        const e = await window.nostr.signEvent({
            created_at: Math.floor(new Date().getTime() / 1000),
            kind: 10000222,
            tags: [],
            content: 'i want in',
        });

        const requestBody = {
            event_from_user: e,
            request: ["delete_reserved_name", { "name": `${name}` }], // Ensures name is passed as a plain string
        };

        const response = await fetch('https://admin.primal.net/api2', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        if (data && !data.error) {
            sendSuccess('Handle deleted successfully.');
            fetchReservedNames(); // Refresh the list
        } else {
            sendWarning('Failed to delete the handle. Please try again.');
        }
    } catch (error) {
        console.error('Error deleting reserved name:', error);
        sendWarning('Failed to delete the handle. Please try again.');
    }
};






    // Initial fetch of reserved names
    useEffect(() => {
        fetchReservedNames();
    }, []);

    return (
        <>
            <div className="MainBody">
                <div className="UMTitle">reserved names</div>
                <div className="InputContainerRN">
                    <div className="InputGroup">
                        <label className="InputLabel" htmlFor="input1">Handle</label>
                        <div className="InputWithButton">
                            <input
                                type="text"
                                id="input1"
                                className="CustomInput"
                                value={newHandle}
                                onChange={(e) => setNewHandle(e.target.value)}
                            />
                            <button className="IconButton" onClick={addReservedName}>
                                <img src={addHandle} alt="Add Handle" className="ButtonImage" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="TableContainer">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <table className="ReservedNamesTable">
                            <thead>
                                <tr>
                                    <th>Handle</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reservedNames.map((name, index) => (
                                    <tr key={index}>
                                        <td>{name}</td>
                                        <td>
                                            <button
                                                className="DeleteReserveButton"
                                                onClick={() => deleteReservedName(name)}
                                            >
                                                <img src={Delete} alt="delete" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
};

export default ReservedNames;
