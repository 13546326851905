import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';

const PromoBatch = () => {
    const { name } = useParams();
    const [batchData, setBatchData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('all');

    useEffect(() => {
        const fetchBatchData = async () => {
            try {
                const e = await window.nostr.signEvent({
                    created_at: Math.floor(new Date().getTime() / 1000),
                    kind: 10000222,
                    tags: [],
                    content: 'fetch promo code batch',
                });

                const response = await fetch('https://admin.primal.net/api2', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        event_from_user: e,
                        request: ['promo_code_batch', { name, limit: 500, offset: 0 }],
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch batch data');
                }

                const data = await response.json();
                setBatchData(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching batch data:', error);
                setLoading(false);
            }
        };

        fetchBatchData();
    }, [name]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    };

    const filteredCodes = batchData?.codes?.filter((code) => {
        if (activeTab === 'all') return true;
        if (activeTab === 'redeemed') return code.status === 'REDEEMED';
        if (activeTab === 'open') return code.status === 'OPEN';
        return false;
    });

    return (
        <div className="MainBody">
            <div className="UMTitle">promo codes for "{name}"</div>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div className="TransactionTable">
                    <div className="TransactionTabs">
                        <button
                            className={`TabButton ${activeTab === 'all' ? 'ActiveTab' : ''}`}
                            onClick={() => setActiveTab('all')}
                        >
                            <span>{batchData.number_of_codes}</span><p>All Codes</p>
                        </button>
                        <button
                            className={`TabButton ${activeTab === 'redeemed' ? 'ActiveTab' : ''}`}
                            onClick={() => setActiveTab('redeemed')}
                        >
                            <span>{batchData.number_of_redeemed}</span><p>Redeemed</p>
                        </button>
                        <button
                            className={`TabButton ${activeTab === 'open' ? 'ActiveTab' : ''}`}
                            onClick={() => setActiveTab('open')}
                        >
                            <span>{batchData.number_of_open}</span><p>Open</p>
                        </button>
                    </div>
                    <table className="transaction_table">
                        <thead>
                            <tr className="table_header">
                                <th>Code</th>
                                <th>Status</th>
                                <th>Redeemed By</th>
                                <th>Date of Expiration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCodes?.map((code, index) => (
                                <tr className="table_row" key={index}>
                                    <td>{code.code}</td>
                                    <td>{code.status}</td>
                                    <td>{code.redeemed_by_pubkey || 'N/A'}</td>
                                    <td>{formatDate(code.expires_at)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default PromoBatch;
