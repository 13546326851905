import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import GenerateButton from '../Images/GenerateButton.svg';
import Delete from '../Images/Delete.svg';

const PromoCodes = () => {
    const [batches, setBatches] = useState([]);
    const [loading, setLoading] = useState(true);

    // Form state
    const [batchName, setBatchName] = useState('');
    const [count, setCount] = useState(0);
    const [discountPerc, setDiscountPerc] = useState(0);
    const [expiresAt, setExpiresAt] = useState('');

    const fetchPromoCodes = async () => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));

            const e = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'fetch promo codes',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: e,
                    request: ['promo_codes', { limit: 500, offset: 0 }],
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setBatches(data.batches || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching promo codes:', error);
        }
    };

    useEffect(() => {
        fetchPromoCodes();
    }, []);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    };

    const onGenerate = async () => {
        try {
            const expiresTimestamp = Math.floor(new Date(expiresAt).getTime() / 1000);

            const e = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'generate promo code batch',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: e,
                    request: ['promo_code_generate_batch', {
                        name: batchName,
                        count: parseInt(count, 10),
                        discount_perc: parseInt(discountPerc, 10),
                        expires_at: expiresTimestamp,
                    }],
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate promo code batch');
            }

            const result = await response.json();
            console.log('Promo code batch generated:', result);
            
            setBatchName('');
            setCount(0);
            setDiscountPerc(0);
            setExpiresAt('');

            setLoading(true);
            await fetchPromoCodes();

        } catch (error) {
            console.error('Error generating promo code batch:', error);
        }
    };

    return (
        <div className="MainBody">
            <div className="UMTitle">promo codes</div>
            <div className="createBatchFormHorizontal">
                <div className="formTitle">Create New Batch</div>
                <div className="formFields">
                    <div className="formColumn">
                        <label>Batch Name</label>
                        <input
                            type="text"
                            className="batchNameInput"
                            value={batchName}
                            onChange={(e) => setBatchName(e.target.value)}
                        />
                    </div>
                    <div className="formColumn">
                        <label># of Codes</label>
                        <input
                            type="number"
                            className="codesInput"
                            value={count}
                            onChange={(e) => setCount(e.target.value)}
                        />
                    </div>
                    <div className="formColumn">
                        <label>Discount %</label>
                        <input
                            type="number"
                            className="discountInput"
                            value={discountPerc}
                            onChange={(e) => setDiscountPerc(e.target.value)}
                        />
                    </div>
                    <div className="formColumn">
                        <label>Date of Expiration</label>
                        <input
                            type="date"
                            className="expirationInput"
                            value={expiresAt}
                            onChange={(e) => setExpiresAt(e.target.value)}
                        />
                    </div>
                    <div className="formColumn">
                        <label>Label</label>
                        <input type="text" className="labelInput" />
                    </div>
                    <div className="formColumn">
                        <label>SKU</label>
                        <select className="skuSelect">
                            <option>1M</option>
                            <option>3M</option>
                            <option>12M</option>
                        </select>
                    </div>
                    <button className="generateButton" onClick={onGenerate}>
                        <img src={GenerateButton} className="generateButtonImage" alt="" />
                    </button>
                </div>
            </div>

            <div className="existingBatchesTable">
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Batch Names</th>
                            <th># of Codes</th>
                            <th>Wallet Created On</th>
                            <th>Discount %</th>
                            <th>Expiration Date</th>
                            <th>SKU</th>
                            <th>Label</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" style={{ textAlign: 'center' }}>Loading...</td>
                            </tr>
                        ) : (
                            batches.map((batch, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link to={`/premium/batch/${batch.name}`} className="batch-link">
                                            <span className="statusDot green"></span> {batch.name}
                                        </Link>
                                    </td>
                                    <td>{batch.count}</td>
                                    <td>{formatDate(batch.created_at)}</td>
                                    <td>{batch.discount_perc}%</td>
                                    <td>{formatDate(batch.expires_at)}</td>
                                    <td>{batch.product_id || 'N/A'}</td>
                                    <td>{batch.product_label || 'N/A'}</td>
                                    <td>
                                        <button className="deleteButton">
                                            <img src={Delete} alt="" />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PromoCodes;
