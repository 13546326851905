import React, { useState, useEffect } from 'react';
import '../App.css';
import ChangeNameB from '../Images/ChangeName.svg';
import DefaultAvatarCN from '../Images/default avatar.svg';
import { bech32 } from '@scure/base';
import { useToastContext } from './Toast';

const ChangeName = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [npub, setNpub] = useState('');
    const [newName, setNewName] = useState('');
    const [loading, setLoading] = useState(true);
    const [profilePicture, setProfilePicture] = useState(DefaultAvatarCN); // State for the avatar

    const { sendWarning, sendSuccess } = useToastContext(); // Access toast functions

    // Function to convert npub to hex
    const npubDecode = (nip19) => {
        try {
            const { prefix, words } = bech32.decode(nip19, 5000);
            const data = new Uint8Array(bech32.fromWords(words));
            if (prefix !== 'npub') throw new Error('Invalid prefix');
            return Array.from(data)
                .map(byte => byte.toString(16).padStart(2, '0'))
                .join('');
        } catch (error) {
            throw new Error('Error decoding npub: ' + error.message);
        }
    };

    const hexToNpub = (hex) => {
        const words = bech32.toWords(Uint8Array.from(hex.match(/.{2}/g).map(byte => parseInt(byte, 16))));
        return bech32.encode('npub', words);
    };

    // Fetch subscription data
    const fetchSubscriptions = async () => {
        try {
            const e = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'i want in',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: e,
                    request: ['subscriptions', { limit: 500, offset: 0 }],
                }),
            });

            const data = await response.json();
            if (data && !data.error) {
                setSubscriptions(data.subscriptions || []);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
            sendWarning('Failed to fetch subscriptions.');
            setLoading(false);
        }
    };

    // Fetch profile picture for the user
    const fetchProfilePicture = async (selectedPubkey) => {
        const payload = JSON.stringify(['user_infos', { "pubkeys": [selectedPubkey] }]);

        try {
            const response = await fetch('https://dev.primal.net/api', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: payload,
            });

            if (response.ok) {
                const data = await response.json();
                if (data && Array.isArray(data) && data.length > 0) {
                    const userData = JSON.parse(data[0].content);
                    const profilePictureUrl = userData.picture;
                    setProfilePicture(profilePictureUrl || DefaultAvatarCN); // Use the fetched URL or fallback to default
                } else {
                    setProfilePicture(DefaultAvatarCN);
                }
            } else {
                console.error('Failed to fetch profile picture:', response.statusText);
                setProfilePicture(DefaultAvatarCN);
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            setProfilePicture(DefaultAvatarCN);
        }
    };

    // Change Name API call
    const handleChangeName = async () => {
        try {
            if (!npub || !newName) {
                sendWarning('Both fields are required.');
                return;
            }

            const pubkey = npubDecode(npub); // Convert npub to hex (using your decode logic)

            const e = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'i want in',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: e,
                    request: ['membership_change_name', { pubkey, name: newName }],
                }),
            });

            const data = await response.json();

            if (data && !data.error) {
                sendSuccess('Name changed successfully.');
                setNpub('');
                setNewName('');
            } else {
                sendWarning('Failed to change name. Please try again.');
            }
        } catch (error) {
            console.error('Error changing name:', error);
            sendWarning('Error changing name. Please try again.');
        }
    };

    // Trigger fetch on component load
    useEffect(() => {
        fetchSubscriptions();
    }, []);

    // Handle click on a name
    const handleNameClick = (receiverPubkey) => {
        setNpub(hexToNpub(receiverPubkey)); // Fill the npub field
        fetchProfilePicture(receiverPubkey); // Fetch and update the avatar
    };

    return (
        <div className="MainBody">
            <div className="UMTitle">change name</div>
            <div className="InputContainerCN">
                <div className="AvatarCN">
                    <img className="AvatarCN" src={profilePicture} alt="user avatar" />
                </div>
                <div className="InputGroupCN">
                    <label className="InputLabel" htmlFor="npubInput">Npub</label>
                    <input
                        type="text"
                        id="npubInput"
                        className="NpubCN"
                        value={npub}
                        onChange={(e) => setNpub(e.target.value)}
                    />
                </div>
                <div className="InputGroupCN">
                    <label className="InputLabel" htmlFor="newNameInput">New Name</label>
                    <input
                        type="text"
                        id="newNameInput"
                        className="newNameCN"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                    />
                </div>
                <button className="ChangeNameButton" onClick={handleChangeName}>
                    <img src={ChangeNameB} alt="Change Name Button" />
                </button>
            </div>
            <div className="TableContainer">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <table className="ReservedNamesTable">
                        <thead>
                            <tr>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscriptions.map((sub, index) => (
                                <tr key={index} onClick={() => handleNameClick(sub.receiver_pubkey)}>
                                    <td className="ClickableName">{sub.name || 'Unknown'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default ChangeName;
