import React, { useState } from 'react';
import '../App.css';
import ActivateButton from '../Images/ActivateButton.svg';
import DefaultAvatar from '../Images/default avatar.svg'; // Import your default avatar
import { bech32 } from '@scure/base';
import { hexToBytes } from '@noble/hashes/utils';
import { useToastContext } from '../Components/Toast'; // Adjust the path as needed

const Bech32MaxSize = 5000;

function npubDecode(nip19) {
    try {
        const { prefix, words } = bech32.decode(nip19, Bech32MaxSize);
        const data = new Uint8Array(bech32.fromWords(words));
        if (prefix !== 'npub') throw new Error('Invalid prefix');
        return bytesToHex(data);
    } catch (error) {
        throw new Error('Error decoding npub: ' + error.message);
    }
}

function bytesToHex(bytes) {
    return Array.from(bytes)
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('');
}

const ActivateManually = () => {
    const [npub, setNpub] = useState('');
    const [refId, setRefId] = useState('');
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [profilePicture, setProfilePicture] = useState(DefaultAvatar); // Default avatar
    const toast = useToastContext(); // Use the toast context

    const fetchProfilePicture = async (hexPubkey) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const payload = JSON.stringify(['user_infos', { pubkeys: [hexPubkey] }]);

        try {
            const response = await fetch('https://dev.primal.net/api', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: payload,
            });

            if (response.ok) {
                const data = await response.json();
                if (data && Array.isArray(data) && data.length > 0) {
                    const userData = JSON.parse(data[0].content);
                    setProfilePicture(userData.picture || DefaultAvatar);
                }
            } else {
                console.error('Failed to fetch profile picture:', response.statusText);
                setProfilePicture(DefaultAvatar); // Fallback to default avatar
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            setProfilePicture(DefaultAvatar); // Fallback to default avatar
        }
    };

    const fetchUserData = async (hexPubkey) => {
        try {
            const payload = JSON.stringify(['wallet_account', { pubkey: hexPubkey }]);
            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: payload,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data.');
            }

            const data = await response.json();
            setUserData(data); // Update user data in the state
        } catch (error) {
            console.error('Error fetching user data:', error);
            toast.sendWarning('Failed to fetch user data.');
        }
    };

    const handleActivate = async () => {
        setLoading(true);
        try {
            // Convert npub to hex pubkey
            const hexPubkey = npubDecode(npub);

            // Sign the event
            const e = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'Activate wallet',
            });

            // Make the API request for wallet activation
            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: e,
                    request: ['wallet_account_activate', { pubkey: hexPubkey, ref_id: refId }],
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to activate wallet. Please check the input values.');
            }

            toast.sendSuccess('Wallet activated successfully!');

            // Fetch the user profile picture and user data
            await Promise.all([fetchProfilePicture(hexPubkey), fetchUserData(hexPubkey)]);
        } catch (error) {
            toast.sendWarning(error.message || 'An error occurred while activating the wallet.');
            console.error('Activation error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="MainBody">
            <div className="UMTitle">activate manually</div>
            <div className="ActivateManuallyForm">
                <div className="ActivateManuallyInputContainer">
                    <label htmlFor="npub" className="ActivateManuallyLabel">Npub:</label>
                    <input
                        type="text"
                        id="npub"
                        className="ActivateManuallyInput ActivateManuallyInputNpub"
                        value={npub}
                        onChange={(e) => setNpub(e.target.value)}
                    />
                </div>
                <div className="ActivateManuallyInputContainer">
                    <label htmlFor="refId" className="ActivateManuallyLabel">Ref ID:</label>
                    <input
                        type="text"
                        id="refId"
                        className="ActivateManuallyInput ActivateManuallyInputRefId"
                        value={refId}
                        onChange={(e) => setRefId(e.target.value)}
                    />
                </div>
                <button
                    className="ActivateManuallyButtons"
                    onClick={handleActivate}
                    disabled={loading}
                >
                    {loading ? 'Activating...' : <img src={ActivateButton} alt="Activate" />}
                </button>
            </div>
            {userData && (
                <div className="UserDisplay">
                    <img
                        src={profilePicture}
                        alt="User Avatar"
                        className="UserAvatar"
                    />
                    <div className="UserDetails">
                        <div className="DetailRow">
                            <span className="DetailLabel">KYC Name</span>
                            <span className="DetailValue">{userData.kyc_name || 'N/A'}</span>
                        </div>
                        <div className="DetailRow">
                            <span className="DetailLabel">Verified Email</span>
                            <span className="DetailValue">{userData.verified_email || 'N/A'}</span>
                        </div>
                        <div className="DetailRow">
                            <span className="DetailLabel">Lightning Address</span>
                            <span className="DetailValue">{userData.lightning_address || 'N/A'}</span>
                        </div>
                        <div className="DetailRow">
                            <span className="DetailLabel">Verified Nostr Address</span>
                            <span className="DetailValue">{userData.verified_nostr_address || 'N/A'}</span>
                        </div>
                        <div className="DetailRow">
                            <span className="DetailLabel">Wallet Created On</span>
                            <span className="DetailValue">
                                {userData.wallet_created_on || 'N/A'}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ActivateManually;
