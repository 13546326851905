import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const timestampToMonthYear = (timestamp) => {
    const tsInMilliseconds = timestamp * 1000;
    const date = new Date(tsInMilliseconds);
    const options = { month: 'short', year: '2-digit', timeZone: 'UTC' }; // Explicitly specify UTC
    return date.toLocaleDateString('en-US', options);
  };

const MonthlyRevenuesGraph = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null); // Reference to the chart instance
  const [chartData, setChartData] = useState({
    legend: [],
    regular: [],
    combined_labels: []
  });
  const [isFetchingDone, setIsFetchingDone] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingDone(false);
        const e = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want in',
        });

        const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            event_from_user: e,
            request: ['subscriptions', { limit: 10000, offset: 0 }],
          }),
        });

        if (response.ok) {
          const data = await response.json();
          const legendData = data.monthly_subscriptions_revenue_usd.legend;
          const regularData = data.monthly_subscriptions_revenue_usd.regular;

          // Extract and combine labels, then sort in ascending order
          const legendLabels = legendData.map(item => parseInt(item[0], 10));
          const regularLabels = regularData.map(item => parseInt(item[0], 10));
          const combinedLabels = Array.from(new Set([...legendLabels, ...regularLabels])).sort((a, b) => a - b);

          // Create a function to align data with the combined labels
          const alignDataWithLabels = (labels, data) => {
            const dataMap = labels.reduce((acc, label, index) => {
              acc[label] = parseFloat(data[index][1]);
              return acc;
            }, {});
            return combinedLabels.map(label => dataMap[label] !== undefined ? dataMap[label] : 0);
          };

          // Align datasets
          const alignedLegend = alignDataWithLabels(legendLabels, legendData);
          const alignedRegular = alignDataWithLabels(regularLabels, regularData);

          setChartData({
            legend: alignedLegend,
            regular: alignedRegular,
            combined_labels: combinedLabels
          });
        } else {
          console.error('Failed to fetch data from the API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsFetchingDone(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isFetchingDone && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Destroy the existing chart instance if it exists
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const timestamps = chartData.combined_labels.map(timestampToMonthYear);

      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: timestamps,
          datasets: [
            {
              label: 'Legend',
              data: chartData.legend,
              backgroundColor: '#FFA030',
            },
            {
              label: 'Premium',
              data: chartData.regular,
              backgroundColor: '#B71874',
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: false,
              stacked: true,
            },
            y: {
              display: false,
              stacked: true,
            },
          },
          plugins: {
            legend: {
              display: false, // No graph legend
            },
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              callbacks: {
                title: function (context) {
                  return context[0].label; // Use month and year as the title
                },
                label: function (context) {
                  const datasetLabel = context.dataset.label;
                  const value = context.parsed.y;
                  const formattedValue = `$${value.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}`;
                  return `${datasetLabel}: ${formattedValue}`;
                },
              },
            },
          },
          maintainAspectRatio: false,
        },
      });
    }

    // Cleanup function to destroy the chart instance when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData, isFetchingDone]);

  return (
    <div>
      <canvas ref={chartRef} style={{ height: '120px', width: '100%' }} />
    </div>
  );
};

export default MonthlyRevenuesGraph;
