import React, { useEffect, useState } from 'react';
import '../App.css';
import ActivationsChart2 from './ActivationGraph2';
import SubsChart2 from './SubsChart2';
import HomeChartData2 from './Graphs2';
import StackedBarChartMAU2 from './Graphs3';
import TransactionsChart from './TransactionChart';
import { hexToBytes } from '@noble/hashes/utils';
import { bech32 } from '@scure/base';

function formatNumberWithCommas(number) {
  return number ? number.toLocaleString() : "0";
}

const npubEncode = (hex) => {
  const Bech32MaxSize = 5000;
  const words = bech32.toWords(hexToBytes(hex));
  return bech32.encode('npub', words, Bech32MaxSize);
};

const Home2 = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const signEvent = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want in',
        });

        const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            event_from_user: signEvent,
            request: ['home', {}],
          }),
        });

        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const {
    wallet,
    subscriptions,
    authenticated
  } = data || {};

  const activatedWallets = formatNumberWithCommas(wallet?.number_of_activated_wallets || 0);
  const activeSubscriptions = formatNumberWithCommas(subscriptions?.active_subscriptions || 0);
  const primalMAU = formatNumberWithCommas(authenticated?.MAU || 0);

  return (
    <div className="MainBody">
      {loading ? (
        <div className="loader"><span></span></div>
      ) : (
        <>
          <div className="Container1">
            <div className="GraphContainerFour">
              <div className="GroupContainerA">
                <div className="ChartTitleDAUA">Active Wallets</div>
                <div className="Group588">
                  <p>  </p>
                </div>
              </div>
              <div className="chartplaceB">
                <ActivationsChart2 />
              </div>
            </div>
            <div className="GraphContainerFour">
              <div className="GroupContainerA">
                <div className="ChartTitleDAUA">Active Subs</div>
                <div className="Group588">
                  <p>   </p>
                </div>
              </div>
              <div className="chartplaceB">
                <SubsChart2 />
              </div>
            </div>
          </div>
          <div className="Container1">
            <div className="GraphContainerFour">
              <div className="GroupContainer">
                <div className="ChartTitleDAUB">Primal MAU</div>
                <div className="Group500">
                  <p>   </p>
                </div>
              </div>
              <div className="chartplaceC">
                <HomeChartData2 />
              </div>
            </div>
            <div className="GraphContainerFour">
              <div className="GroupContainer">
                <div className="ChartTitleDAUB">Daily  MAU</div>
                <div className="Group500">
                  <p>   </p>
                </div>
              </div>
              <div className="chartplaceC">
                <StackedBarChartMAU2 />
              </div>
            </div>
          </div>
          <div className="Container1">
          <div className="GraphContainerFour">
              <div className="GroupContainerA">
                <div className="ChartTitleDAUA">Cummulative Transactions</div>
                <div className="Group588">
                  <p>   </p>
                </div>
              </div>
              <div className="chartplaceB">
                <TransactionsChart />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home2;
