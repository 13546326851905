import React, { useState, useEffect } from 'react';
import '../App.css';
import logo from '../Images/logo export.svg';
import { useLocation, useHistory } from 'react-router-dom';
import ProfileComponent from './ProfileComponent';
import { useSystemStatus } from './SystemStatus'; // Import the custom hook

function Sidebar({ pubkey }) {
  const location = useLocation();
  const history = useHistory();
  const [accessiblePaths, setAccessiblePaths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { systemData } = useSystemStatus();

  const setLoading = (state) => {
    setIsLoading(state);
  };

  const fetch_admin = async (role, func, args) => {
    setLoading(true);
    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'i want in',
      });

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['admin', { role, func, args }],
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      setLoading(false);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      return { error: error.message };
    }
  };

  useEffect(() => {
    const fetchAccessiblePaths = async () => {
      if (pubkey) {
        const response = await fetch_admin('everyone', 'get_accessible_paths', { pubkey });
        if (!response.error && response.accessible_paths) {
          setAccessiblePaths(response.accessible_paths);
        }
      }
    };

    fetchAccessiblePaths().catch(console.error);
  }, [pubkey]);

  const navItems = [
    { title: 'HOME', link: '/home' },
    { title: 'HOME2', link: '/home2' },
    { title: 'PREMIUM', link: '/premium' },
    { title: 'ACTIVE USERS', link: '/active_users' },
    { title: 'VERIFIED USERS', link: '/verified_users' },
    { title: 'PRIMAL WALLET', link: '/primal_wallet', subPaths: ['/user'] },
    { title: 'USER MODERATION', link: '/user_moderation' },
    { title: 'UPLOADED MEDIA', link: '/uploaded_media' },
    { title: 'NETWORK STATS', link: '/network_stats' },
    {
      title: 'SYSTEM STATUS',
      link: '/monitoring',
      hasError: systemData ? systemData.components.some((component) => !component.status) : false,
    },
    { title: 'ADMIN', link: '/admin' },
  ];

  const isAccessible = (path) => accessiblePaths.includes(path);

  const handleNavigation = (link) => {
    if (isAccessible(link)) {
      history.push(link);
    }
  };

  const isActive = (navItem) => {
    if (location.pathname === navItem.link) {
      return true;
    }
    if (navItem.subPaths) {
      return navItem.subPaths.some((subPath) => location.pathname === subPath);
    }
    return false;
  };

  return (
    <>
      <div className="Sidebar">
        <a href="https://admin.primal.net" className="logo">
          <img src={logo} alt="Logo" />
        </a>
        <ul className="SidebarList">
          {navItems.map((navItem, key) => (
            isAccessible(navItem.link) && (
              <li
                key={key}
                className={`row ${isActive(navItem) ? 'active' : ''}`}
                onClick={() => handleNavigation(navItem.link)}
              >
                <div className={`HomeTitle ${isActive(navItem) ? 'active' : ''}`}>
                  {navItem.title}
                  {navItem.hasError && <span className="NotificationBadge" />}
                </div>
              </li>
            )
          ))}
        </ul>
        <ProfileComponent setPubkey={() => {}} />
      </div>
      {isLoading && <div className="MainBody"></div>}
    </>
  );
}

export default Sidebar;
