import React, { useState, useEffect } from 'react';
import '../App.css';
import { useToastContext } from './Toast';
import { bech32 } from '@scure/base';
import { hexToBytes } from '@noble/hashes/utils';
import DefaultAvatar from '../Images/default avatar.svg';

const Bech32MaxSize = 5000;

const npubEncode = (hex) => {
    try {
        const words = bech32.toWords(hexToBytes(hex));
        return bech32.encode('npub', words, Bech32MaxSize);
    } catch (error) {
        console.error("Error encoding to npub:", error);
        return "Invalid Public Key";
    }
};

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
};

const ExpiredSubs = () => {
    const [expiredSubs, setExpiredSubs] = useState([]);
    const [avatars, setAvatars] = useState({});
    const [loading, setLoading] = useState(false);
    const { sendSuccess, sendWarning } = useToastContext();

    const fetchAvatars = async (pubKeys) => {
        const payload = JSON.stringify(["user_infos", { "pubkeys": pubKeys }]);
        try {
            const response = await fetch("https://dev.primal.net/api", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: payload,
            });

            if (response.ok) {
                const data = await response.json();
                const avatarsData = {};
                if (data && Array.isArray(data)) {
                    data.forEach(user => {
                        try {
                            const userData = JSON.parse(user.content);
                            avatarsData[user.pubkey] = userData.picture || DefaultAvatar;
                        } catch (err) {
                            console.error("Error parsing user content:", err);
                        }
                    });
                }
                setAvatars(avatarsData);
            } else {
                console.error("Error fetching avatars:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching avatars:", error);
        }
    };

    const fetchExpiredSubscriptions = async () => {
        setLoading(true);
        try {
            const e = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'i want in',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: e,
                    request: ['expired_subscriptions', {}],
                }),
            });

            const data = await response.json();
            if (data && !data.error) {
                setExpiredSubs(data);
                const pubKeys = [...new Set(data.map(sub => sub.pubkey))];
                fetchAvatars(pubKeys);
            } else {
                sendWarning('Failed to fetch expired subscriptions. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching expired subscriptions:', error);
            sendWarning('Failed to fetch expired subscriptions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchExpiredSubscriptions();
    }, []);

    return (
        <div className="MainBody">
             <div className="UMTitle">expired subs</div>
             <div className="LHeaders">
                <div className="LHeader1">User</div>
                <div className="LHeader2">Created At</div>
                <div className="LHeader3">Valid Until</div>
                <div className="LHeader4">Origin</div>
             </div>
            <div className="SubscriptionList">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    expiredSubs.map((sub, index) => (
                        <div key={index} className="SubscriptionRow">
                            <div className="Avatar">
                                <a href={`https://primal.net/p/${npubEncode(sub.pubkey)}`} target="_blank" rel="noopener noreferrer">
                                    <img src={avatars[sub.pubkey] || DefaultAvatar} alt="Avatar" className="avatar-img" />
                                </a>
                            </div>
                            <div className="RowItem NameRowItem">
                                <a href={`https://primal.net/p/${npubEncode(sub.pubkey)}`} target="_blank" rel="noopener noreferrer" className="NameLink">
                                    {sub.name || 'Unknown'}
                                </a>
                            </div>
                            <div className="RowItem DateRowItem">{formatDate(sub.created_at)}</div>
                            <div className="RowItem DateRowItem">{formatDate(sub.valid_until)}</div>
                            <div className="RowItem">{sub.origin || 'N/A'}</div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ExpiredSubs;
