import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import Vector149 from '../Images/Vector 149.svg';
import { hexToBytes } from '@noble/hashes/utils';
import { bech32 } from '@scure/base';
import ActivateManuallyButton from '../Images/ActivateManuallyButton.svg';

const PrimalWallet = () => {
  const [walletData, setWalletData] = useState({
    strikeAccountBalance: null,
    primalAccountBalance: null,
    numberOfWallets: null,
    number_of_transactions: null,
    number_of_transactions_24h: null,
    transaction_volume: null,
    transaction_volume_24h: null,
    number_of_pending_transactions: null,
    volume_of_pending_transactions: null,
    number_of_activated_wallets: null,
  });
  const [usersData, setUsersData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [freezedData, setFreezedData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [loading, setLoading] = useState(true);
  const [kycLevel, setKycLevel] = useState(2);
  const [loadingTable, setLoadingTable] = useState(false);
  const observer = useRef();
  const limit = 50;
  const hasMore = useRef(true);
  const [offset, setOffset] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFreezed, setIsFreezed] = useState(false);
  const [activeDataType, setActiveDataType] = useState('initial');
  const [searchParams, setSearchParams] = useState({
    username: '',
    pubkey: '',
    email: '',
    verified_nostr_address: '',
    nostr_lightning_address: '',
  });
  const [sortBy, setSortBy] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');

  const Bech32MaxSize = 5000;

  // Helper functions for Bech32 encoding/decoding
  const npubEncode = (hex) => encodeBytes('pubkey', hexToBytes(hex));
  const npubDecode = (nip19) => {
    try {
      const { prefix, words } = bech32.decode(nip19, Bech32MaxSize);
      const data = new Uint8Array(bech32.fromWords(words));
      if (prefix !== 'npub') throw new Error('Invalid prefix');
      return bytesToHex(data);
    } catch (error) {
      throw new Error('Error decoding npub: ' + error.message);
    }
  };

  const bytesToHex = (bytes) =>
    Array.from(bytes).map((byte) => byte.toString(16).padStart(2, '0')).join('');
  const encodeBytes = (prefix, bytes) => {
    const words = bech32.toWords(bytes);
    return bech32.encode(prefix, words, Bech32MaxSize);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    setOffset(0); // Reset offset to 0 for the initial load
    hasMore.current = true; // Reset hasMore for a new data load
    await fetchData(0, kycLevel, false); // Fetch regular data
    setLoading(false);
  };

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const fetchCountries = async () => {
    try {
      const event = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'Fetch countries',
      });
      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: event,
          request: ['wallet_countries', {}],
        }),
      });
      const data = await response.json();
      if (typeof data === 'object' && data !== null) {
        const countriesArray = Object.entries(data).map(([code, name]) => ({
          code,
          name,
        }));
        setCountries(countriesArray);
      } else {
        console.error('Expected an object of countries but got:', data);
        setCountries([]); // Set to an empty array if data is not an object
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setCountries([]); // Ensure countries is set to an empty array on error
    }
  };

  useEffect(() => {
    fetchCountries();
  }, [kycLevel]);

  useEffect(() => {
    setSearchParams((prev) => ({ ...prev, country: selectedCountry }));
  }, [selectedCountry]);

  // Shared function for fetching data (standard and sorted)
  const fetchData = async (newOffset = 0, kyc = kycLevel, sorted = false) => {
    setLoadingTable(true);
    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'i want in',
      });
      const requestPayload = sorted
        ? ['wallet_totals', { kyc_level: kyc, offset: newOffset, limit, sortby: sortBy, sortdirection: sortDirection }]
        : ['wallet_totals', { kyc_level: kyc, offset: newOffset, limit }];

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: requestPayload,
        }),
      });

      const data = await response.json();

      // Check if data was returned and update the state accordingly
      if (data.users && data.users.length > 0) {
        setUsersData((prevUsers) => [...prevUsers, ...data.users]);
        setOffset((prevOffset) => prevOffset + data.users.length);

        // If the number of items fetched is less than the limit, assume there are no more items
        if (data.users.length < limit) {
          hasMore.current = false;
        }
      } else {
        hasMore.current = false; // No more data to fetch
      }

      // Update wallet data
      setWalletData({
        strikeAccountBalance: data.strike_account_balance,
        primalAccountBalance: data.all_primal_wallets_balance,
        numberOfWallets: data.number_of_wallets,
        number_of_transactions: data.number_of_transactions,
        number_of_transactions_24h: data.number_of_transactions_24h,
        transaction_volume: data.transaction_volume,
        transaction_volume_24h: data.transaction_volume_24h,
        number_of_pending_transactions: data.number_of_pending_transactions,
        volume_of_pending_transactions: data.volume_of_pending_transactions,
        number_of_activated_wallets: data.number_of_activated_wallets,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingTable(false);
    }
  };

  // Observer creation function
  const createObserver = () => {
    return new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore.current && !loadingTable) {
        setLoadingTable(true);
        if (activeDataType === 'sorted') {
          // Fetch more sorted data
          fetchData(offset, kycLevel, true).finally(() => setLoadingTable(false));
        } else {
          // Fetch more regular data
          fetchData(offset, kycLevel, false).finally(() => setLoadingTable(false));
        }
      }
    });
  };

  useEffect(() => {
    const observerInstance = createObserver();
    if (observer.current) {
      observerInstance.observe(observer.current);
    }
    return () => {
      if (observer.current) observerInstance.disconnect();
    };
  }, [offset, sortBy, sortDirection, activeDataType]); // Dependencies

  const fetchSortedData = async () => {
    setLoadingTable(true);
    setOffset(0);
    setUsersData([]);
    setActiveDataType('sorted'); // Set active data type to sorted
    hasMore.current = true; // Reset hasMore for sorted data fetch
    await fetchData(0, kycLevel, true); // Pass `true` for sorted fetch
    setLoadingTable(false);
  };

  const handleSortChange = (column) => {
    setSortBy(column);
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    fetchSortedData();
  };

  const getCurrentData = () => {
    switch (activeDataType) {
      case 'searched':
        return searchedData;
      case 'freezed':
        return freezedData;
      default:
        return usersData;
    }
  };

  function formatTimestamp(timestamp) {
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit' };
    return new Date(timestamp).toLocaleDateString('en-US', dateOptions) + ' at ' + new Date(timestamp).toLocaleTimeString('en-US', timeOptions);
  }


  const handleKycCheckboxChange = (event) => {
    setKycLevel(event.target.checked ? 2 : 0);
    if (event.target.checked) {
        setIsFreezed(false);
    }
};

const handleFreezedCheckboxChange = (event) => {
  setIsFreezed(event.target.checked);
  if (event.target.checked) {
      setKycLevel(0);
  }
};

const fetchSearchData = async () => {
  setLoadingTable(true);
  setSearchedData([]);
  setActiveDataType('searched');

  let searchQuery = {};
  if (searchParams.pubkey) {
      try {
          const hexNpub = npubDecode(searchParams.pubkey);
          searchQuery.pubkey = hexNpub;
      } catch (error) {
          console.error('Error decoding npub:', error);
          setLoadingTable(false);
          return;
      }
  }

  Object.entries(searchParams).forEach(([key, value]) => {
      if (value && key !== 'pubkey') {
          searchQuery[key] = value;
      }
  });

  try {
      const event = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'Search request',
      });
      const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              event_from_user: event,
              request: ["wallet_totals", { "kyc_level": kycLevel, "only_freezed": isFreezed, "offset": 0, "limit": 50, "search": searchQuery }]
          }),
      });
      const data = await response.json();
      if (data.users && data.users.length > 0) {
          setSearchedData(data.users);
      } else {
          setSearchedData([]);
      }
  } catch (error) {
      console.error('Error fetching search data:', error);
  } finally {
      setLoadingTable(false);
  }
};

  return (
    <div className="MainBody">
      {loading && (
        <div className="loader"><span></span></div>
      )}
      {!loading && (
        <div>
          <div className="ActivateManuallyButtonArea">
            <Link to="/primal_wallet/activate_manually">
              <button className="ActivateManuallyButton">
                <img src={ActivateManuallyButton} alt="ActivateButton" />
              </button>
            </Link>
          </div>
          <div className="Container1">
            <div className="StrikeAccountBalance">
              <div className="GroupContainer">
                <div className="StrikeAccountBalanceTitle">Strike Account Balance (BTC)</div>
                <div className="Group500">
                  <p>{(walletData.strikeAccountBalance || 0).toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div className="DailyTransactions">
            <div className="GroupContainer">
                <div className="DailyTransactionsTitle"># of Wallets</div>
                <div className="Group510">
                <p>{kycLevel ? (walletData.number_of_activated_wallets || 0).toLocaleString() : (walletData.numberOfWallets || 0).toLocaleString()}</p>
                </div>
            </div>
            </div>
            <div className="DailyTransactions">
              <div className="GroupContainer">
                <div className="DailyTransactionsTitle">Total Tx's</div>
                <div className="Group510">
                  <p>{(walletData.number_of_transactions || 0).toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div className="DailyTransactions">
              <div className="GroupContainer">
                <div className="DailyTransactionsTitle">Total Tx Sats</div>
                <div className="Group510">
                  <p>{(walletData.transaction_volume || 0 * 100000000).toLocaleString()}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="Container2">
            <div className="NumberOfWallets">
              <div className="GroupContainer">
                <div className="NumberOfWalletsTitle">Balance Check</div>
                <div className={`Group510 ${walletData.strikeAccountBalance < walletData.primalAccountBalance ? 'negative' : walletData.strikeAccountBalance > walletData.primalAccountBalance ? 'positive' : 'zero'}`}>
                  <p>
                    {((walletData.strikeAccountBalance - walletData.primalAccountBalance || 0) * 100000000).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
            <div className="TotalTransactions">
              <div className="GroupContainer">
                <div className="TotalTransactionsTitle">Pending Tx's</div>
                <div className="Group510">
                  <p>{(walletData.number_of_pending_transactions || 0).toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div className="DailyTransactions">
              <div className="GroupContainer">
                <div className="DailyTransactionsTitle">Pending Sats</div>
                <div className="Group510">
                  <p>{(walletData.volume_of_pending_transactions || 0 * 100000000).toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div className="DailyTransactions">
              <div className="GroupContainer">
                <div className="DailyTransactionsTitle">24hr Tx's</div>
                <div className="Group510">
                  <p>{(walletData.number_of_transactions_24h || 0).toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div className="DailyTransactions">
              <div className="GroupContainer">
                <div className="DailyTransactionsTitle">24hr Tx Sats</div>
                <div className="Group510">
                  <p>{(walletData.transaction_volume_24h || 0 * 100000000).toLocaleString()}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="SearchButtonDrop">
          <button className="SearchWallet" onClick={toggleExpand}>
            Search <img src={Vector149} className={`ArrowIcon ${isExpanded ? 'rotated' : ''}`} alt="arrow" />
          </button>
        </div>
        {isExpanded && (
          <div className="ExpandableSearchSection">
          <div className="UserNostrLightningSearch">
          <div className="InputContainer">
          <label htmlFor="username" className="label">Username:</label>
          <input
          type="text"
          id="username"
          className="UserNameSearch"
          value={searchParams.username}
          onChange={(e) => setSearchParams(prev => ({ ...prev, username: e.target.value }))}
          />
          </div>
          <div className="InputContainer">
          <label htmlFor="nostrAddress" className="label">Verified Email:</label>
          <input
          type="text"
          id="nostrAddress"
          className="NostrAddressSearch"
          value={searchParams.email}
          onChange={(e) => setSearchParams(prev => ({ ...prev, email: e.target.value }))}
          />
          </div>
          <div className="InputContainer">
          <label htmlFor="lightningAddress" className="label">Lightning Address:</label>
          <input
          type="text"
          id="lightningAddress"
          className="LightningAddressSearch"
          value={searchParams.nostr_lightning_address}
          onChange={(e) => setSearchParams(prev => ({ ...prev, nostr_lightning_address: e.target.value }))}
          />
          </div>
          <div className="InputContainer">
          <label htmlFor="nostrAddress" className="label">Nostr Address:</label>
          <input
          type="text"
          id="nostrAddress"
          className="NostrAddressSearch"
          value={searchParams.verified_nostr_address}
          onChange={(e) => setSearchParams(prev => ({ ...prev, verified_nostr_address: e.target.value }))}
          />
          </div>
          <div className="InputContainer">
              <label htmlFor="country" className="label">Country:</label>
              <select
                id="country"
                className="CountrySearch"
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                <option value="">Select a country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>{country.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="OtherSearch">
          <div className="InputContainer">
          <label htmlFor="searchDate" className="label">Npub:</label>
          <input
          type="text"
          id="searchDate"
          className="NpubWalletSearch"
          value={searchParams.pubkey}
          onChange={(e) => setSearchParams(prev => ({ ...prev, pubkey: e.target.value }))}
          />
          </div>
          <div className="CheckBoxesWallet">
            <div className="ActivatedKycCheckbox">
              <label className="container">Activated
                <input type="checkbox" checked={kycLevel} onChange={handleKycCheckboxChange} />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="KycCheckbox">
              <label className="container">Frozen
                <input type="checkbox" checked={isFreezed} onChange={handleFreezedCheckboxChange} />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
<button className="SubmitSearch" onClick={fetchSearchData}>Submit</button>
</div>
</div>
          )}
          <div>
            <table className="wallet_table">
              <thead>
                <tr className="table_header">
                  <th>Username</th>
                  <th>Nostr Address</th>
                  <th>Lightning Address</th>
                  <th onClick={() => handleSortChange('created_at')} className="sortable-column">
                    Wallet Created On {sortBy === 'created_at' && (sortDirection === 'asc' ? '▲' : '▼')}
                  </th>
                  <th>Wallet Balance</th>
                  <th># Txs</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentData().map((user, index) => (
                  <tr className="table_row" key={user.pubkey}>
                    <td>
                      <Link to={`/user/${user.pubkey}`} className="NostrAddress">
                        {user.pubkey_resolved && user.pubkey_resolved.nostr_lightning_address
                          ? user.pubkey_resolved.nostr_lightning_address.split('@')[0]
                          : user.pubkey_resolved && user.pubkey_resolved.display_name
                          ? `nostr: ${user.pubkey_resolved.display_name}`
                          : ''}
                      </Link>
                    </td>
                    <td>
                      {user.pubkey_resolved && typeof user.pubkey_resolved.verified_nostr_address === "string" ? (
                        <a className="NostrAddress" href={user.pubkey_resolved.link} target="_blank" rel="noopener noreferrer">
                          {user.pubkey_resolved.verified_nostr_address}
                        </a>
                      ) : (
                        <a className="NostrAddress" href={user.pubkey_resolved.link} target="_blank" rel="noopener noreferrer">
                          [not set]
                        </a>
                      )}
                    </td>
                    <td className={user.pubkey_resolved && user.pubkey_resolved.nostr_lightning_address && user.pubkey_resolved.nostr_lightning_address.endsWith('@primal.net') ? '' : 'red-text'}>
                      {user.pubkey_resolved && user.pubkey_resolved.nostr_lightning_address}
                    </td>
                    <td>{user.created_at && formatTimestamp(user.created_at)}</td>
                    <td>{user.balance && (user.balance * 100000000).toLocaleString()}</td>
                    <td>{user.number_of_transactions && user.number_of_transactions.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div ref={observer} style={{ textAlign: 'center' }}>
        {loadingTable && <div className="loader" style={{ textAlign: 'center', marginTop: '20px' }}><span></span></div>}
      </div>
    </div>
  );
};

export default PrimalWallet;