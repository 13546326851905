import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const timestampToDateString = (timestamp) => {
    const tsInMilliseconds = timestamp * 1000;
    const date = new Date(tsInMilliseconds);
    
    // Define abbreviated day and month names
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dayName = days[date.getUTCDay()];
    const monthName = months[date.getUTCMonth()];
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${dayName}, ${monthName} ${day}, ${year}`;
};



const StackedBarChartMAU = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null); // Reference for the Chart instance
  const [chartData, setChartData] = useState({
    ios: [],
    web: [],
    android: [],
    combined_labels: [],
  });

  const [isFetchingDone, setIsFetchingDone] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingDone(false);
        const e = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want in',
        });

        const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            event_from_user: e,
            request: ['home', {}],
          }),
        });

        if (response.ok) {
          const data = await response.json();
          const mauData = data.mau_timeseries;

          const iosData = mauData.ios;
          const webData = mauData.web;
          const androidData = mauData.android;

          // Combine all timestamps and sort them
          const combinedLabels = Array.from(new Set([
            ...iosData.map((entry) => entry[0]),
            ...webData.map((entry) => entry[0]),
            ...androidData.map((entry) => entry[0]),
          ])).sort((a, b) => a - b);

          const alignDataWithLabels = (dataset) => {
            const dataMap = dataset.reduce((acc, [timestamp, value]) => {
              acc[timestamp] = value;
              return acc;
            }, {});
            return combinedLabels.map((label) => dataMap[label] || 0);
          };

          setChartData({
            ios: alignDataWithLabels(iosData),
            web: alignDataWithLabels(webData),
            android: alignDataWithLabels(androidData),
            combined_labels: combinedLabels,
          });
        } else {
          console.error('Failed to fetch data from the API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsFetchingDone(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isFetchingDone && chartRef.current) {
      // Destroy the existing chart instance if it exists
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      const timestamps = chartData.combined_labels.map(timestampToDateString);

      // Create a new chart instance
      chartInstanceRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: timestamps,
          datasets: [
            {
              label: 'iOS',
              data: chartData.ios,
              backgroundColor: '#FA3D3D',
            },
            {
              label: 'Web',
              data: chartData.web,
              backgroundColor: '#B71874',
            },
            {
              label: 'Android',
              data: chartData.android,
              backgroundColor: '#FFA030',
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: false,
              stacked: true,
            },
            y: {
              display: false,
              stacked: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  return label;
                },
              },
            },
          },
          maintainAspectRatio: false,
        },
      });
    }

    return () => {
      // Cleanup the chart instance on unmount
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartData, isFetchingDone]);

  return (
    <div>
      <canvas ref={chartRef} style={{ height: '200px', width: '90%' }} />
    </div>
  );
};

export default StackedBarChartMAU;
